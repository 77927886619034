<template>
  <div>
    <b-card title="Añadir Estructura" class="text-center">
      <hr>
      <b-row class="mb-2 mx-3">
        <b-col cols="4">Nombre</b-col>
        <b-col cols="8">
          <b-form-input type="text" v-model="complements.name"
            class="form-control-merge text-center" name="width" />
        </b-col>
      </b-row> 
      <b-row>
        <b-form-group class="mx-auto" size="sm">
          <b-form-radio-group v-model="complements.registro_tipo"
            :options="[{ text: 'Hueco luz', value: 1 }, { text: 'Hueco + Registro', value: 2 }, { text: 'Medida Total', value: 3 }]"
            plain class="" />
        </b-form-group>
      </b-row>
      <b-form-group v-if="complements.registro_tipo == 2 || complements.registro_tipo == 3" label-cols-md="4"
        label-size="sm" label="Medida Registro" label-for="registro-medida" class="mx-3">
        <b-form-input id="registro-medida" size="sm" v-model="complements.registro_medida" type="number" />
      </b-form-group>
      <b-row class="mx-3">
        <b-col cols="5" class="text-center">Ancho</b-col>
        <b-col cols="2"></b-col>
        <b-col cols="5" class="text-center">Alto</b-col>
      </b-row>
      <b-row class="mx-3 mb-2">
        <b-col cols="5">
          <b-form-input id="width" type="number" v-model.number="dimensions.width"
            class="form-control-merge text-center" name="width" />
        </b-col>
        <b-col cols="2" class="text-center">X</b-col>
        <b-col cols="5">
          <b-form-input id="height" type="number" v-model.number="dimensions.height"
            class="form-control-merge text-center" name="height" />
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-2">
        <b-col cols="4">Persiana</b-col>
        <b-col cols="8">
          <b-form-radio-group v-model="complements.persiana" :options="sino_options" name="persiana" />
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="complements.persiana">
        <b-col cols="4">Descripción Persiana</b-col>
        <b-col cols="8">
          <b-form-textarea id="persiana_desc" size="sm" name="persiana_desc" v-model="complements.persiana_desc"
            no-resize />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="4">Tapeta</b-col>
        <b-col cols="8">
          <b-form-radio-group v-model="complements.tapeta" :options="sino_options" name="tapeta" />
        </b-col>
      </b-row> 
      <b-container v-if="complements.tapeta" class="border border-secondary p-1 mb-2">
        <b-row class="text-left justify-content-md-center mb-1">
            <b-col cols="4"> 
                <b-form-checkbox v-model="tapeta_selected[0]" value="1" @change="frameCheck(0)" switch>Arriba</b-form-checkbox>           
            </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="complements.tapeta_values[0]" class="form-control-merge text-center" :disabled="!tapeta_selected[0]"/>
        </b-col>
            <!-- <b-form-select v-model="complements.tapeta_values[0]" :options="tapeta_values" size="sm" :disabled="!tapeta_selected[0]"></b-form-select> -->
        </b-row> 
        <b-row class="text-left justify-content-md-center mb-1">
            <b-col cols="4"> 
                <b-form-checkbox v-model="tapeta_selected[1]" value="1" @change="frameCheck(1)" switch >Abajo</b-form-checkbox>           
            </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="complements.tapeta_values[1]" class="form-control-merge text-center" :disabled="!tapeta_selected[1]"/>
            <!-- <b-form-select v-model="complements.tapeta_values[1]" :options="tapeta_values" size="sm" :disabled="!tapeta_selected[1]"></b-form-select>             -->
          </b-col>
        </b-row> 
        <b-row class="text-left justify-content-md-center mb-1">
            <b-col cols="4"> 
                <b-form-checkbox v-model="tapeta_selected[2]" value="1" @change="frameCheck(2)" switch>Izquierda</b-form-checkbox>           
            </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="complements.tapeta_values[2]" class="form-control-merge text-center" :disabled="!tapeta_selected[2]"/>
            <!-- <b-form-select v-model="complements.tapeta_values[2]" :options="tapeta_values" size="sm" :disabled="!tapeta_selected[2]"></b-form-select>                      -->
          </b-col>
        </b-row> 
        <b-row class="text-left justify-content-md-center">
          <b-col cols="4"> 
            <b-form-checkbox v-model="tapeta_selected[3]" value="1" @change="frameCheck(3)" switch>Derecha</b-form-checkbox>               
            </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="complements.tapeta_values[3]" class="form-control-merge text-center" :disabled="!tapeta_selected[3]"/>
            <!-- <b-form-select v-model="complements.tapeta_values[3]" :options="tapeta_values" size="sm" :disabled="!tapeta_selected[3]"></b-form-select>                      -->
          </b-col>
        </b-row> 
      </b-container>
      <b-button type="button" variant="success" block @click="next"> Continuar </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormDatepicker,
  BCol,
  BRow,
  BFormTextarea,
  BFormInput,
  BCardText,
  BButton,
  BFormSelect,
  BFormRadioGroup,
  BFormGroup,
  BContainer,
  BFormCheckbox,
  BFormCheckboxGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from 'vuex';

export default {
  components: {
    BCard,
    BFormDatepicker,
    BCol,
    BRow,
    BFormTextarea,
    BCardText,
    BButton,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
    BFormGroup,
    BContainer,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  data() {
    return {
      dimensions: { 'width': 0, 'height': 0 },
      complements: {
        name : "",
        persiana: false,
        registro_medida: null,
        registro_tipo: 1,
        persiana_desc: "",
        tapeta: false,
        tapeta_values : [0,0,0,0] 
      },
      sino_options: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      tapeta_selected : ["1","1","1","1"],
      tapeta_values: [
          { value: 0, text: '0' },
          { value: 20, text: '20' },
          { value: 40, text: '40' },
          { value: 80, text: '80' },
        ],
    };
  },
  methods: {
    next() {
      if (this.dimensions.width < 1 || this.dimensions.height < 1) {
        this.$bvToast.toast('Las medidas introducidas no son las correctas', { variant: 'danger', title: 'Error' });
        return 0;
      }
      if (this.complements.registro_medida == null && (this.complements.registro_tipo == 2 || this.complements.registro_tipo == 3)) {
        this.$bvToast.toast('Debe introducir la medida del registro', { variant: 'danger', title: 'Error' });
        return 0;
      }
      if (this.complements.registro_tipo == 3) {
        this.dimensions.height = this.dimensions.height - this.complements.registro_medida;
      }
      if (this.complements.registro_tipo == 1) {
        this.complements.registro_medida = null;
      }
      this.$store.commit('ventanas/setDimensions', this.dimensions);
      this.$store.commit('ventanas/setComplements', this.complements)
      this.$router.push("/vdivisions");
    },
    frameCheck(index){
      if (!this.tapeta_selected[index]) {
        this.complements.tapeta_values[index] = 0;
        this.$forceUpdate();
      }
    }

  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>